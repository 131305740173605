import React, { useState, useEffect, useContext } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Masonry from "react-responsive-masonry"

// CSS

import './Discover.css'
import './HamburguerMenu.css'

// Media

import Logo from '../../../assets/logo.png';
import CompanyLogo from '../../../assets/logo.png';

// Global Variables

const API_ENDPOINT = "https://api.scoutzapp.com"
//const API_ENDPOINT = "https://api.scoutzapp.com"


function Discover() {
  
  const navigate = useNavigate();

  const [teams, setTeams] = useState([]);
  const [teamsLoaded, setTeamsLoaded] = useState(false);

  const [athletes, setAthletes] = useState([]);
  const [athletesLoaded, setAthletesLoaded] = useState(false);

  const [newsArticles, setNewsArticles] = useState([]);
  const [newsLoaded, setNewsLoaded] = useState(false);

  const [jobs, setJobs] = useState([]);
  const [jobsLoaded, setJobsLoaded] = useState(false);

  const images = [
    "https://picsum.photos/200/300?image=1050",
    "https://picsum.photos/300/300?image=206",
    "https://picsum.photos/300/300?image=208",
    "https://picsum.photos/300/300?image=268",
    "https://picsum.photos/300/300?image=202",
    "https://picsum.photos/300/300?image=185",
    "https://picsum.photos/300/300?image=125",
    "https://picsum.photos/300/300?image=145",
    "https://picsum.photos/300/300?image=135",
  ]
 
  return (
    <>

    <Header />
   
    <Container className="create-account-container">

      <Masonry columnsCount={3} gutter="10px">
                {images.map((image, i) => (
                    <img
                        key={i}
                        src={image}
                        style={{width: "100%", display: "block"}}
                    />
                ))}
      </Masonry>

    </Container>

    <Footer />
    </>
  );

}

export default Discover;