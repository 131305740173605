import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import Logo from '../../../assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import './Overview.css'
import './HamburguerMenu.css'
import './EntityList.css'
import CompanyLogo from '../../../assets/logo.png';

const API_ENDPOINT = "https://api.scoutzapp.com"
//const API_ENDPOINT = "https://api.scoutzapp.com"

const fetchConversations = async (profileId) => {
  const response = await fetch(`${API_ENDPOINT}/profiles/id/${profileId}/conversations`);
  
  
  if (!response.success) {
    //alert("Can't load conversations")
    //throw new Error('Network response was not ok.');
  }


  return await response.json();
};

const EntityList = ({ data }) => {
  
  const navigate = useNavigate();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return 'No messages';
    }
    return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handleRowClick = (conversationId) => {
    navigate(`/chat/id/${conversationId}`);
  };

  return (
    <div>

      <Row>
        <Col xs={9}>
          <div className="entity-title">Messages</div>
        </Col>
        <Col xs={3} className="text-end text-white">
          <a href="/chat/requests">Requests</a>
        </Col>
      </Row>

      {data.length == 0 && (

        <Row>
          
          <Col className="text-center text-white mt-4">
            <p>No messages</p>  
          </Col>

        </Row>
        

      )}

      {data.map((conversation) => {
        const lastMessageSent = conversation.lastMessageSent;
        const participants = conversation.participants;
        const otherParticipant = participants.find(p => p._id !== (lastMessageSent.profile?._id || ''));
        const lastMessage = lastMessageSent.profile 
          ? `${otherParticipant?.title}: ${lastMessageSent.text}`
          : 'Start a conversation';

        return (
          <Row onClick={() => handleRowClick(conversation._id)} key={conversation._id} style={{ marginBottom: 15 }}>
            <Col xs={3}>
              <div
                className="circle-img"
                style={{
                  backgroundImage: `url(${otherParticipant?.profileImage || 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg'})`,
                }}
              />
            </Col>
            <Col xs={6}>
              <div className="" style={{ 'fontSize' : '1.2em', 'color' : '#fff' }}>{otherParticipant?.title || 'Unknown'}</div>
              {/*<div className="description">{lastMessage}</div>*/}
            </Col>
            <Col xs={3} style={{ textAlign: 'right', fontSize: '0.8em', color: '#fff' }}>
              <p>{formatTimestamp(lastMessageSent.timestamp)}</p>
            </Col>
          </Row>
        );
      })}

    </div>
  );
};;

const ChatOverview = () => {

  const [conversations, setConversations] = useState([]);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedProfile = localStorage.getItem('profile');
    console.log("Stored Profile is")
    console.log(storedProfile)
    if (storedProfile) {
      setProfile(JSON.parse(storedProfile));
    }
  }, []);

  useEffect(() => {

    const loadData = async () => {
      console.log("LOAD DATA")
      console.log(profile)
      if (profile) {
        console.log("INSIDE IF PROFILE")
        
        try {
          const conversationsData = await fetchConversations(profile._id);
          console.log(conversationsData.data)
          setConversations(conversationsData.data);
          setIsLoading(true);
        } catch (error) {
          console.error('Failed to load conversations:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadData();

  }, [profile]);

  return (
    <>
      <Header />
      <Container className="create-account-container">
        {!isLoading && <EntityList data={conversations} />}
      </Container>
    </>
  );

}

export default ChatOverview;