import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './Header.css';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faTv, faNewspaper, faSearch, faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight, faBell } from '@fortawesome/free-solid-svg-icons'
import { slide as Menu } from 'react-burger-menu'
import CompanyLogo from '../../../assets/logo.png';
import SearchDiv from './SearchDiv';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

//const API_ENDPOINT = "https://api.scoutzapp.com"
const API_ENDPOINT = "https://api.scoutzapp.com"

function Header() {

    const navigate = useNavigate();

    const [menuDisplay, setMenuDisplay] = useState(false);
    const [searchDisplay, setSearchDisplay] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
  
    const [isRecruiting, setIsRecruiting] = useState(false);

    const { t, i18n } = useTranslation();

    const handleRecruitingToggle = () => {
      
      const profileData = localStorage.getItem('profile');
    
      if (profileData) {
        const parsedProfile = JSON.parse(profileData);
        if (parsedProfile.people && parsedProfile.people[0].firstname && parsedProfile.username) {
          if (['agent', 'coach', 'team'].includes(parsedProfile.type)) {
            const url = `${API_ENDPOINT}/profiles/id/${parsedProfile._id}/${parsedProfile.type}/recruiting`;
            const options = {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ recruiting: !parsedProfile.recruiting })
            };
            fetch(url, options)
              .then(response => response.json())
              .then(data => {
                if (data.success) {
                  setIsRecruiting(data.data.recruiting);
                } else {
                  throw new Error(data.message || "Error toggling recruiting status");
                }
              })
              .catch(error => {
                console.error('Error toggling recruiting status:', error);
              });
          }
        }
      }

    };

    const handleOnOpen = () => {
        setMenuDisplay(true)
    }

    const handleSearchOnOpen = () => {
      console.log("Display search")
      setSearchDisplay(true)
    }

    const handleBack = () => {

    };

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
      const profile = JSON.parse(localStorage.getItem('profile'));

      if (profile && (profile.type === 'athlete' || profile.type === 'team')) {
        const items = [
          { route: `/profile/${profile.type}`, display: t('menu.myprofile') },
          { route: `/profile/${profile.type}/edit`, display: t('menu.editprofile') },
          { route: "/settings/paymentmethods", display: t('menu.paymentmethods') },
          { route: "/subscriptions/overview", display: t('menu.subscription') },
          //{ route: "/profile/verify", display: t('menu.verifyme')},
          { route: "/chat/overview", display: t('menu.conversations') },
          { route: "/proday", display: "My Scoutz Pro Day" },
          { route: "/rating/overview", display: "Scoutz Rating" },
          { route: "/jobs/my", display: t('menu.myjobs') },
          { route: "/contactus", display: t('menu.contactus') },
          { route: "/settings", display: t('menu.settings') },
        ];
        
        setMenuItems(items);
      }

      if (profile && (profile.type === 'team')) {
        const items = [
          { route: `/profile/${profile.type}`, display: t('menu.myprofile') },
          { route: "/settings/paymentmethods", display: t('menu.paymentmethods') },
          //{ route: "/subscriptions/overview", display: t('menu.subscription') },
          //{ route: "/profile/verify", display: t('menu.verifyme')},
          { route: "/chat/overview", display: t('menu.conversations') },
          { route: "/proday", display: "My Scoutz Pro Day" },
          //{ route: "/rating/overview", display: "Scoutz Rating" },
          { route: "/contactus", display: t('menu.contactus') },
          { route: "/settings", display: t('menu.settings') },
        ];
        
        setMenuItems(items);
      }

      if (profile && (profile.type === 'coach')) {
        const items = [
          { route: `/profile/${profile.type}`, display: t('menu.myprofile') },
          { route: "/settings/paymentmethods", display: t('menu.paymentmethods') },
          //{ route: "/subscriptions/overview", display: t('menu.subscription') },
          //{ route: "/profile/verify", display: t('menu.verifyme')},
          { route: "/chat/overview", display: t('menu.conversations') },
          { route: "/proday", display: "My Scoutz Pro Day" },
          //{ route: "/rating/overview", display: "Scoutz Rating" },
          { route: "/contactus", display: t('menu.contactus') },
          { route: "/settings", display: t('menu.settings') },
        ];
        
        setMenuItems(items);
      }

      if (profile && (profile.type === 'agent')) {
        const items = [
          { route: `/profile/${profile.type}`, display: t('menu.myprofile') },
          { route: "/settings/paymentmethods", display: t('menu.paymentmethods') },
          //{ route: "/subscriptions/overview", display: t('menu.subscription') },
          //{ route: "/profile/verify", display: t('menu.verifyme')},
          { route: "/chat/overview", display: t('menu.conversations') },
          { route: "/proday", display: "My Scoutz Pro Day" },
          //{ route: "/rating/overview", display: "Scoutz Rating" },
          { route: "/contactus", display: t('menu.contactus') },
          { route: "/settings", display: t('menu.settings') },
        ];
        
        setMenuItems(items);
      }

      // re-run effect when t changes
    }, [t]);

    
    function UserComponent({ userData }) {
        const styles = {
          container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          },
          img: {
            borderRadius: "50%",
            marginTop: "15px",
            height: "100px",
            width: "100px",
            margin: 0
          },
          name: {
            color: '#fff',
            fontWeight: "bold",
            marginTop: "5px",
            marginBottom: "0px"
          },
          nickname: {
            fontWeight: "lighter",
            opacity: 0.6,
            marginBottom: "25px"
          }
        };
        const placeholderImage = "https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg";
      
        return (
          <div style={styles.container}>
            <img
              style={styles.img}
              src={userData.image || placeholderImage}
              alt="User Profile"
            />
            <div style={styles.name}>{userData.name}</div>
            <div style={styles.nickname}>@{userData.nickname}</div>
          </div>
        )
    }

    useEffect(() => {

      let token = localStorage.getItem('token');
      let profileType = localStorage.getItem('profileType');
      let profile = localStorage.getItem('profile');

      console.log(token)
      console.log(profileType)
      console.log(JSON.parse(profile))

    }, []);

    const [userProfile, setUserProfile] = useState(undefined);
    const [isLoaded, setIsLoaded] = useState(false);

    const ToggleSwitch = ({ isOn, handleToggle }) => {
      return (
        <label className="toggle-switch">
          <input type="checkbox" checked={isOn} onChange={handleToggle} />
          <span className="switch" />
        </label>
      );
    };

    // Precisa ajustar aqui. Fazer API call versus buscar do storage profile

    // 1 - Se o profile.type for 'agent' or 'coach' or 'team', then it should load each model on api, to check if isRecruiting is on or off
    // 2 - 

    useEffect(() => {
      const profileData = localStorage.getItem('profile');

      console.log("Profile Data second ")
      console.log(profileData)

      if (profileData) {
        const parsedProfile = JSON.parse(profileData);
        console.log("Parsed data is ")
        console.log(parsedProfile)
        setUserProfile(parsedProfile);
      
        if (parsedProfile.people && parsedProfile.people[0].firstname && parsedProfile.username) {
          if (['agent', 'coach', 'team'].includes(parsedProfile.type)) {
            const url = `${API_ENDPOINT}/profiles/id/${parsedProfile._id}/${parsedProfile.type}`;
            fetch(url)
              .then(response => response.json())
              .then(data => {
                if (data.success) {
                  setIsRecruiting(data.data.recruiting === true);
                }
              })
              .catch(error => {
                console.error('Error fetching recruiting status:', error);
              });
          }
          setIsLoaded(true);
        }
      }

    }, []);

  return (
    <>

        <Container style={{ 'height' : 'unset' }}>

          <Row>
              
              <Col xs={12} md={6} lg={4} className="mx-auto">

                {/*  
              
                  <div style={{
                      position: "absolute",
                      left: "15px",
                      top: "30px",
                      color: "white",
                      display: "inline-block",
                      cursor: "pointer"
                  }}
                      onClick={handleOnOpen}>

                  </div>

                */}

                {/* 

                  <div style={{
                      position: "absolute",
                      right: "20px",
                      top: "30px",
                      color: "white",
                      display: "inline-block",
                      cursor: "pointer",
                      fontSize : '1.2em'
                  }}
                      onClick={() => navigate('/notifications/overview')}>
                      <FontAwesomeIcon icon={faBell} />
                  </div>
                  */}

      
                  <img src={CompanyLogo} width={50} alt="Logo" className="mt-4 my-4 d-block mx-auto" />
              
              
              </Col>
          
          </Row>

        </Container>
        

    </>
  );
}

export default Header;