import React, { useEffect } from 'react';

// Packages

import { useNavigate } from 'react-router-dom';
import { Container, Image } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';

// Media

import Logo from '../../assets/logo.png';

/*



  START PAGE



*/

export default function Index() {

  const navigate = useNavigate();

  const Pulsate = keyframes`
    0% {
      transform: scale(0.95);
    }
    70% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(0.95);
    }
  `;

  const StyledImage = styled(Image)`
    animation: ${Pulsate} 1s ease-in-out infinite;
  `;

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/accounts/login');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <StyledImage src={Logo} />
    </Container>
  );
}
