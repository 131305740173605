import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faHeart, faShare } from "@fortawesome/free-solid-svg-icons";

function Settings() {

    const navigate = useNavigate();
    const [menuDisplay, setMenuDisplay] = useState(false);

    const handleOnOpen = () => {
        setMenuDisplay(true);
    }

    useEffect(() => {}, []);

    const handleNavigation = (path) =>{
        navigate(path);
    }

    // Stories

    function ScrollableStoriesRow({  }) {

        // stories

        const stories = [
            { id : "1", src : "https://i.pinimg.com/736x/e9/0e/a8/e90ea8a103b984d3322e797fd353dcfb--prom-hair-lady-gaga.jpg", title : "Lady Gaga"  },
            { id : "2", src : "https://pyxis.nymag.com/v1/imgs/7d4/7d3/09dd244022bc7205ec55fd6b09e53c40b1-the-weeknd.rsquare.w1200.jpg", title : "The Weeknd"  },

        ];
    
        const [selected, setSelected] = React.useState([]);
        const isItemSelected = (id) => !!selected.find((el) => el === id);
        const handleClick = (id) => ({ getItemById, scrollToItem }) => {
        const itemSelected = isItemSelected(id);
        setSelected((currentSelected) =>
            itemSelected ? currentSelected.filter((el) => el !== id) : currentSelected.concat(id)
        );
    
    };
    
        return (
            <div className="col-12 d-flex flex-column mb-4">
   
                <div style={{ position: 'relative', height : '100px', width : '100%' }}>
                    <div style={{ position: 'absolute',  width : '100%', 'overflowX': 'scroll', 'left' : '-25px' }} className="d-flex flex-row overflow-auto pb-4">
                        {stories.map(({id, src, title }, index) => 
                            <ProfileStory 
                                id={id}
                                itemId={id} 
                                src={src} 
                                title={title} 
                                onClick={handleClick(id)}
                                selected={isItemSelected(id)}
                                index={index}
                            />)
                        }
                    </div>
                </div>
        
            </div>
        )
    
    }
    
    const ImageComponent = ({ src, fallbackSrc }) => {
        // Check if 'src' is provided, else use 'fallbackSrc'
        const imageUrl = src ? src : fallbackSrc;
    
        return (
        <div style={{
                borderRadius: '50%',
                margin: '1px auto', 
                width: '60px', 
                height: '60px', 
                background: `url(${imageUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
        }} />
        );
    }
    
    function ProfileStory ({itemId, src, title, index, ...restProps }) {

        const navigate = useNavigate();

        const handleNavigate = () => {
            navigate(`/profile/id/${itemId}`);
        };
    
        return (
        <div 
            onClick={() => restProps.onClick({ getItemById: undefined, scrollToItem: undefined })}
            style={{ width: '60px', margin: index === 0 ? '0 12px 0 8px' : '0 12px' }}
        >
            <Card onClick={handleNavigate} style={{cursor: 'pointer', width: '8rem', backgroundColor: 'unset', border: 'unset'}}>
            
            <ImageComponent src={src} fallbackSrc="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCifOFpkQ9QbQ_V-Tm1o1FPNpTyrTsHjVqoNCrUmqFxKkDe39qHGOwvbkOIO7ELAykm2Q&usqp=CAU" />
    
            <Card.Body style={{ textAlign: 'center', padding: '0.25rem' }}>
                <Card.Title className="pt-1" style={{color: '#000', fontSize : '0.75em', lineHeight: '1.2', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>{title}</Card.Title>
            </Card.Body>
    
            </Card>
        </div>
        );
    }

    /*

    */
    const Post = () => {
        return (
          <>
            <Row className="mb-3">
              <Col xs={2} className="text-right">
                <Image
                  roundedCircle
                  className="profile-image"
                  src="https://i.pinimg.com/736x/e9/0e/a8/e90ea8a103b984d3322e797fd353dcfb--prom-hair-lady-gaga.jpg"
                  style={{ backgroundColor: "lightgrey", borderRadius: "50%", width: "50px", height: "50px", margin: "2px auto" }}
                />
              </Col>
              <Col xs={8}>
                <Row>
                  <Col style={{ fontWeight: "bold" }}>Lady Gaga</Col>
                </Row>
                <Row>
                  <Col style={{ color: "gray" }}>Los Angeles</Col>
                </Row>
              </Col>
              <Col xs={1}>
                <button type="button" className="btn-dots">
                  <i className="fas fa-ellipsis-vertical" style={{ color: "gray" }}></i>
                </button>
              </Col>
            </Row>
            <hr />
            <div className="image-container">
              <Image
                className="post-image rounded"
                src="https://th.bing.com/th/id/R.9dbea0953c875aaeff8f9faaa269717b?rik=RX0Iq6vCXQ1agQ&riu=http%3a%2f%2fcbsnews1.cbsistatic.com%2fhub%2fi%2f2017%2f02%2f06%2f0f331612-2778-45ef-967f-7877f82d3076%2fgettyimages-633950600.jpg&ehk=drmoTQ1siU%2fu8N19jeY3ZOsIMIItY2X7UBc5IDrTr2k%3d&risl=&pid=ImgRaw&r=0"
                alt="Image"
                style={{ width: "100%", height: "350px", marginBottom: "10px", border: "1px solid #ddd" }}
              />
            </div>
            <Row style={{ 'paddingLeft' : '15px' }}>
              <Col xs={3}>
                <Row className="like-container">
                    <div style={{ 'marginRight' : '5px', 'height' : '30px', 'width' : '30px', 'borderRadius' : '25px', 'backgroundPosition' : 'center', 'backgroundSize' : 'cover', 'backgroundImage' : `url("https://celebmafia.com/wp-content/uploads/2018/07/hailey-baldwin-out-in-new-york-city-07-27-2018-12.jpg")` }} />
                    <div style={{ 'marginRight' : '5px', 'height' : '30px', 'width' : '30px', 'borderRadius' : '25px', 'backgroundPosition' : 'center', 'backgroundSize' : 'cover', 'backgroundImage' : `url("https://th.bing.com/th/id/OIP.vE8w-75wLbtQvxSx1kz0QAAAAA?rs=1&pid=ImgDetMain")` }} />
                    <div style={{ 'marginRight' : '5px', 'height' : '30px', 'width' : '30px', 'borderRadius' : '25px', 'backgroundPosition' : 'center', 'backgroundSize' : 'cover', 'backgroundImage' : `url("https://www.ewrestlingnews.com/wp-content/uploads/2020/05/tyson-scaled.jpg")` }} />
                </Row>
              </Col>
              <Col xs={5} style={{ color: "gray", lineHeight : '30px', 'fontWeight' : 'bold' }}>250 liked</Col>
              <Col>
                <i className="fas fa-heart text-primary"></i>&nbsp;
                <i className="fas fa-share text-primary"></i>
              </Col>
            </Row>
          </>
        );
    };

    return (
        <>
            <Header />
                <Container>

                {/* Scroll Horizontal User Stories */}
                <ScrollableStoriesRow/>

                {/* Timeline Component */}
                <Post />

                </Container>
            <Footer />
        </>
    );
}

export default Settings;