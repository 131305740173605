/*

Vibeon

*/

import React,{useState} from 'react';

// CSS
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

// Components

import './i18n/i18n';

// Packages

import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

// Utils

import withAuth from './withAuth';

// CSS

import './App.css'

// Pages

import Index from './pages/Index/Index'

// Authentication

import ForgotPassword from './pages/Accounts/ForgotPassword/Forgot'
import Login from './pages/Accounts/Login/Login'
import Create from './pages/Accounts/Create/Create'

// Chat

import ChatById from './pages/Chat/ChatById/ChatById'
import ChatOverview from './pages/Chat/Overview/Overview'
import ChatRequests from './pages/Chat/Requests/Requests'

// Timeline

import TimelineOverview from './pages/Timeline/Timeline/Timeline'

// Notifications

import NotificationsOverview from './pages/Notifications/Overview/Overview'

// Create

import CreateOptions from './pages/Create/Options/Options'

// Settings

import Settings from './pages/Settings/Settings/Settings'
import FAQ from './pages/Settings/FAQ/FAQ'
import Languages from './pages/Settings/Languages/Languages'
import Terms from './pages/Settings/Terms/Terms'
import PrivacyPolicy from './pages/Settings/PrivacyPolicy/PrivacyPolicy'
//import PaymentMethods from './pages/Settings/PaymentMethods/PaymentMethods'

// Discover

import Discover from './pages/Discover/Discover/Discover'

/*
  Settings
*/

//const AuthedSettings = withAuth(Settings);
const AuthedFAQ = withAuth(FAQ);
const AuthedLanguages = withAuth(Languages);
const AuthedTerms = withAuth(Terms);
const AuthedPrivacyPolicy = withAuth(PrivacyPolicy);
//const AuthedPaymentMethods = withAuth(PaymentMethods);

/*
  Chat
*/

const AuthedChatById = withAuth(ChatById);
const AuthedChatOverview = withAuth(ChatOverview);
const AuthedChatRequests = withAuth(ChatRequests);

/*


*/

function AppContent() {

  const location = useLocation();

  return (
    <>

      <Routes>     

        <Route path="/" element={<Index />} />

        {/* Timeline */}

        <Route path="/timeline" element={<TimelineOverview />} />

        {/* Authentication */}

        <Route path="/accounts/create" element={<Create />} />
        <Route path="/accounts/login" element={<Login />} />
        <Route path="/accounts/forgot/password" element={<ForgotPassword />} />

        {/* Settings */}

        <Route path="/settings" element={<Settings />} />
        <Route path="/settings/faq" element={<AuthedFAQ />} />
        <Route path="/settings/privacypolicy" element={<AuthedPrivacyPolicy />} />
        {/* <Route path="/settings/paymentmethods" element={<AuthedPaymentMethods />} /> */}
        <Route path="/settings/terms" element={<AuthedTerms />} />
        <Route path="/settings/languages" element={<AuthedLanguages />} />

        {/* Chat */}

        <Route path="/chat/id/:id" element={<AuthedChatById />} />
        <Route path="/chat/overview" element={<AuthedChatOverview />} />
        <Route path="/chat/requests" element={<AuthedChatRequests />} />

        {/* Notifications */}

        <Route path="/notifications/overview" element={<NotificationsOverview />} />

        {/* Discover */}

        <Route path="/discover" element={<Discover />} />

        {/* Create */}

        <Route path="/create/options" element={<CreateOptions />} />

      </Routes>
    </>
  );
}

function App() {

  return (
    <Router>
      <AppContent />
    </Router>
  );

}

export default App;
