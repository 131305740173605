import React, { useState } from 'react';

// Packages

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// CSS

import './Login.css'

// Media

import CompanyLogo from '../../../assets/logo.png';

// Global Variables

const API_ENDPOINT = "https://api.scoutzapp.com"
//const API_ENDPOINT = "https://api.scoutzapp.com"

const LoginPage = () => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  /*

    Implement via Profile.authenticate

  */

  const handleLogin = async (e) => {

    e.preventDefault();

    const loginEndpoint = API_ENDPOINT + '/profiles/authenticate';

    try {

      const response = await fetch(
        loginEndpoint,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email:email.toLowerCase(), password }),
        },
      );

      const data = await response.json();
   
      console.log(data)

      if (data.success) {

        localStorage.setItem('token', data.data.token);
        localStorage.setItem('profileType', data.data.data.type);
        localStorage.setItem('profile', JSON.stringify(data.data.data));

        navigate('/home');

      } else {

        alert(data.message)
        console.log("Error when log in the account")

      }

    } catch (error) {

      console.error(`Error during login: ${error}`);

    }
  };

  const handleOAuthLogin = (platform) => {
    console.log(`Login with ${platform}`);
  };

  const goToHome = () => {
    navigate('/home');
  };

  return (
    <div className="container-central align-items-center justify-content-center vh-100 d-flex">
      
      <Container className="container-central mt-4">
            
            <Row>
            
                <Col xs={12} md={6} lg={4} className="mx-auto">
                    
                    <img src={CompanyLogo} width={50} alt="Logo" className="mt-4 my-4 d-block mx-auto" />
                    
                    <Form onSubmit={handleLogin}>

                        <Form.Group controlId="formBasicEmail" style={{ 'marginBottom' : '10px' }}>
                            <div style={{ 'marginBottom' : '5px' }}>
                              <Form.Label style={{ 'color' : '#fff', 'fontWeight' : 'bold', 'color' : '#000' }} className="mb-4">Email address</Form.Label>
                            </div>
                            
                            <Form.Control style={{ 'width' : '100%', 'border' : '1px solid #fa314687', 'height' : '30px'  }} type="email" value={email} onChange={e => setEmail(e.target.value)} />
                        </Form.Group>
                        
                        <Form.Group controlId="formBasicPassword" className="mt-2">
                          
                          <div style={{ 'marginBottom' : '5px' }}>
                            <Form.Label style={{ 'color' : '#fff', 'fontWeight' : 'bold', 'color' : '#000' }} className="mb-2">Password</Form.Label>
                          </div>
                            <Form.Control style={{ 'width' : '100%', 'border' : '1px solid #fa314687', 'height' : '30px' }} type="password" value={password} onChange={e => setPassword(e.target.value)}/>
                        </Form.Group>

                        <div style={{'textAlign' : 'right', 'paddingTop' : '10px'}}>
                          <Form.Text className="text-right text-primary font-weight-bold"><a href="/accounts/forgot/password" style={{ "color" : "#000", "textDecoration" : "none" }}>Forgot your Password</a></Form.Text>
                        </div>
                        
                        <br></br>

                        <Button variant="primary" style={{ 'font-weight' : '800','color' : 'rgb(121 9 21)', 'backgroundColor' : '#FA3146', 'border' : 'none', 'width' : '100%', 'height' : '40px', 'borderRadius' : '10px' }} onClick={handleLogin} type="submit" className="btn-signin mt-3 w-100">Login</Button>

                    </Form>

                    

                </Col>

            </Row>

            {/*
            <Row className="mt-4">

                        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-center">
                            <Button variant="outline-secondary"
                            className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                            style={{ 'margin' : 'unset !important' }}
                            onClick={() => handleOAuthLogin('Apple')}>
                                <img src='https://www.svgrepo.com/download/303125/apple-logo.svg' />
                            </Button>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-center">
                            <Button variant="outline-secondary"
                            className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                            style={{ 'margin' : 'unset !important' }}
                            onClick={() => handleOAuthLogin('Google')}>
                                <img src='https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png' />
                            </Button>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} className="d-flex justify-content-center">
                            <Button variant="outline-secondary"
                            className="btn btn-outline-secondary d-flex align-items-center justify-content-center"
                            style={{ 'margin' : 'unset !important' }}
                            onClick={() => handleOAuthLogin('Facebook')}>
                                <img src='https://www.gov.br/mre/pt-br/delbrasupa/facebook-logo-blue-circle-large-transparent-png.png/@@images/image.png' />
                            </Button>
                        </Col>

            </Row>
            */}

            <div className="login-position-fixed" style={{ bottom: 0 }}>
              <p className="text-center">Don't have an account. <a href="/accounts/create" className="text-primary font-weight-bold">Sign up</a></p>
            </div>


      </Container>

    </div>
  );

};

export default LoginPage;
