/*

  _________                    __          
 /   _____/ ____  ____  __ ___/  |_________
 \_____  \_/ ___\/  _ \|  |  \   __\___   /
 /        \  \__(  <_> )  |  /|  |  /    / 
/_______  /\___  >____/|____/ |__| /_____ \
        \/     \/                        \/

*/


import React, { useEffect, useState } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages

import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Form, Button, Container, ProgressBar, Image, Card } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faMagnifyingGlass, faBookBookmark, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// CSS

import './Overview.css'

// Media

import Logo from '../../../assets/logo.png';
import CompanyLogo from '../../../assets/logo.png';

// Global Variables

const API_ENDPOINT = "https://api.scoutzapp.com"
//const API_ENDPOINT = "https://api.scoutzapp.com"

/*



  START COMPONENTS



*/

const fetchNotifications = async (profileId) => {

  const response = await fetch(`${API_ENDPOINT}/profiles/id/${profileId}/notifications`);
  
  /*
  if (!response.success) {
    alert("Can't load notifications")
    throw new Error('Network response was not ok.');
  }
  */

  //console.log(response.json())

  return await response.json();

};

const EntityList = ({ data }) => {
  
  const navigate = useNavigate();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) {
      return 'No messages';
    }
    return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <div className="entity-title">Notifications</div>
        </Col>
      </Row>
      {data.length === 0 ? (
        <div className="text-white mt-4" style={{ textAlign: 'center' }}>No notifications</div>
      ) : (
        data.map(item => (
          <Row key={item.id} style={{ 'marginBottom' : 15 }}>
            <Col xs={3}>
              <div 
                className="circle-img"
                style={{
                  backgroundImage: `url(${item.image})`,
                }}
              />
            </Col>
            <Col xs={5}>
              <div className="text-white">{item.title}</div>
              <div className="description">{item.description}</div>
            </Col>
          </Row>
        ))
     )}
    </div>
  );
};

/*



  START PAGE



*/

const NotificationsOverview = () => {

  const [notifications, setNotifications] = useState([]);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedProfile = localStorage.getItem('profile');
    console.log("Stored Profile is")
    console.log(storedProfile)
    if (storedProfile) {
      setProfile(JSON.parse(storedProfile));
    }
  }, []);

  useEffect(() => {

    const loadData = async () => {

      if (profile) {

        try {
          const notificationsData = await fetchNotifications(profile._id);
          console.log(notificationsData.data)
          setNotifications(notificationsData.data);
          setIsLoading(true);
        } catch (error) {
          console.error('Failed to load notifications:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadData();

  }, [profile]);

  return (
    <>
      <Header />
      <Container>
        {!isLoading && <EntityList data={notifications} />}
      </Container>
    </>
  );

}

export default NotificationsOverview;