import { NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faNewspaper, faHeart, faSearch, faSquarePlus, faUser, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import './Footer.css';
import { useTranslation } from 'react-i18next';

function Footer() {
  
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const opacity = path => location.pathname === path ? 1 : 0.35;

  return (
    <div className="footer">

      <NavLink to="/timeline" className="footer-option" style={{ opacity: opacity('/timeline'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#000" }} icon={faHome} />
      </NavLink> 

      <NavLink to="/discover" className="footer-option" style={{ opacity: opacity('/discover'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#000" }} icon={faMagnifyingGlass} />
      </NavLink>

      <NavLink to="/create/options" className="footer-option" style={{ opacity: opacity('/create/options'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#000" }} icon={faSquarePlus} />
      </NavLink>

      <NavLink to="/activity" className="footer-option" style={{ opacity: opacity('/activity'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#000" }} icon={faHeart} />
      </NavLink>

      <NavLink to="/profile" className="footer-option" style={{ opacity: opacity('/profile'), textDecoration: 'none' }}>
        <FontAwesomeIcon style={{ color: "#000" }} icon={faUser} />
      </NavLink>

    </div>
  );
}

export default Footer;