import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Settings() {

    const navigate = useNavigate();
    const [menuDisplay, setMenuDisplay] = useState(false);

    const handleOnOpen = () => {
        setMenuDisplay(true);
    }

    useEffect(() => {}, []);

    const handleNavigation = (path) =>{
        navigate(path);
    }

    const SettingsRow = ({ title, path }) => {
        return (
            <Row className="pt-2 pb-2" style={{ 'height' : '45px', 'borderRadius' : '10px', 'marginLeft' : '15px', 'marginRight' : '15px', 'backgroundColor' : '#fff', 'paddingBottom' : '10px', 'paddingTop' : '10px', 'marginBottom' : '25px' }} onClick={() => handleNavigation(path)}>
                <Col xs={6} style={{color: 'black', fontWeight: 'bold', 'paddingLeft' : '15px'}}>{title}</Col>
                <Col xs={6} className="text-right" style={{color: 'white', textAlign: 'right'}}>
                    <FontAwesomeIcon icon={faChevronRight} style={{ 'color' : '#000' }} />
                </Col>
            </Row>
        )
    }

    return (
        <>
            <Header />
            <Container>
                
                {/* Settings Elements */}
                <SettingsRow title="Language" path="/settings/languages" />
                <SettingsRow title="Payment methods" path="/settings/paymentmethods" />
                <SettingsRow title="Notification" path="/notifications/overview" />
                <SettingsRow title="FAQ" path="/settings/faq" />
                <SettingsRow title="Privacy Policy" path="/settings/privacypolicy" />
                <SettingsRow title="Terms of Service" path="/settings/terms" />

            </Container>
            <Footer />
        </>
    );
}

export default Settings;