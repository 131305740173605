import React, { useState, useEffect, useRef } from 'react';

// Packages

import { Row, Col, Form, Button, Container, ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import InputGroup from 'react-bootstrap/InputGroup'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash , faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons';
// import { Spinner } from 'react-bootstrap';

// CSS

import './Create.css'

// Media

import CompanyLogo from '../../../assets/logo.png';
import countries from './countries.json';

// Global Variables

const API_ENDPOINT = "https://api.scoutzapp.com"
const API_ENDPOINT_FILES = "https://api.scoutzapp.com"

//const API_ENDPOINT = "https://api.scoutzapp.com"
//const API_ENDPOINT_FILES = "http://localhost:8998"

const MAX_SIZE_MB = 1;
const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;

export default function CreateAccount() {

    const [step, setStep] = useState(1);


    const navigate = useNavigate();

    // First Step
    //const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [errors, setErrors] = useState({});

    const [phoneInternationalCode, setPhoneInternationalCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Second Step
    const [userType, setUserType] = useState('');

    // Third Step
    
    const [feets, setFeets] = useState('');
    const [gender, setGender] = useState('');
    const [birthday, setBirthday] = useState('');

    const [inches, setInches] = useState('');
    const [weight, setWeight] = useState('');
    const [currentTeam, setCurrentTeam] = useState('');

    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // const [isLoading, setIsLoading] = useState(false);

    // Athlete
    const [nickname, setNickname] = useState('');
    const [position, setPosition] = useState('');
  
    const [selectedCountry, setSelectedCountry] = useState('');

     // Function to toggle password visibility
     const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Function to toggle confirm password visibility
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    


    const handleNext = () => {
        let isValid = true;
        setErrors({});
        switch (step) {
            case 1:
                if (!firstName) {
                    setErrors( previousErrors => ({ ...previousErrors, firstName: 'First Name is required' }) );
                    isValid = false;
                }
                if (!lastName) {
                    setErrors( previousErrors => ({ ...previousErrors, lastName: 'Last Name is required' }) );
                    isValid = false;
                }
                if (!email) {
                    setErrors( previousErrors => ({ ...previousErrors, email: 'E-mail is required' }) );
                    isValid = false;
                }
                if (!password) {
                    setErrors( previousErrors => ({ ...previousErrors, password: 'Password is required' }) );
                    isValid = false;
                }
                if (!confirmPassword) {
                    setErrors( previousErrors => ({ ...previousErrors, confirmPassword: 'Confirm Password is required' }) );
                    isValid = false;
                }
                if (!phoneInternationalCode) {
                    setErrors( previousErrors => ({ ...previousErrors, phoneInternationalCode: 'Phone International Code is required' }) );
                    isValid = false;
                }
                if (!phoneNumber) {
                    setErrors( previousErrors => ({ ...previousErrors, phoneNumber: 'Phone Number is required' }) );
                    isValid = false;
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (email && !emailRegex.test(email)) {
                    setErrors( previousErrors => ({ ...previousErrors, email: 'Invalid email address' }) );
                    isValid = false;
                }
                const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
                if (phoneNumber && !phoneRegex.test(phoneNumber)) {
                    setErrors( previousErrors => ({ ...previousErrors, phoneNumber: 'Invalid phone number' }) );
                    isValid = false;
                }
                if (password && confirmPassword && password !== confirmPassword) {
                    setErrors( previousErrors => ({ ...previousErrors, confirmPassword: `Passwords don't match` }) );
                    isValid = false;
                }
                if(isValid){
                    setStep(step + 1);
                    break;
                }
                break;
            case 2:
                if (!userType) {
                    setErrors( previousErrors => ({ ...previousErrors, userType: `Please select a user type` }) );
                    return;
                }
                setStep(step + 1);
                break;
            case 3:
                if (userType === 'athlete' && (!feets || !gender || !birthday || !inches || !weight || !currentTeam || !verificationCode)) {                    
                    setErrors( previousErrors => ({ ...previousErrors, userType: `All athlete fields are required.` }) );
                    return;
                }
                setStep(step + 1);
                break;
            default:
                setStep(step + 1);
                break;
        }
    };

    const handleBack = () => {
        if (step == 1) {
            navigate('/');
        }
        if (step > 1) setStep(step - 1);
    };

    async function submitForm(userData) {
        try {

            console.log("User Data inside submit form")
            console.log(userData)
    
            // Define the email validation regex
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
            // Perform validation checks
            if (!emailRegex.test(userData.email)) {
                alert('Invalid email address.');
                return;
            } else if (userData.firstName.length <= 1 || userData.lastName.length <= 1 || userData.password.length <= 1) {
                alert('First name, Last name, and Password must each be more than 1 characters.');
                return;
            }
    
            const formData = new URLSearchParams();
    
            for (const key in userData) {
                formData.append(key, userData[key]);
            }
    
            const response = await fetch(`${API_ENDPOINT}/profiles`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: formData.toString()
            });
    
            const data = await response.json();
    
            if (data.message && data.message.includes('successfully')) {
    
                alert('Account created! You can now login.');
  
                localStorage.clear();
                localStorage.setItem('token', data.token);
                localStorage.setItem('profileType', data.type);
                localStorage.setItem('profile', JSON.stringify(data.profile));
    
                navigate('/home');
    
            } else {
                // Handle failed account creation
                alert(data.error || 'Failed to create account.');
            }
    
        } catch (error) {
            console.error("Error creating account:", error);
            alert('An error occurred during account creation.'); 
        }
    }
    // Validation function for email
const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError("");
    }
  };

    const renderStepContent = () => {
        switch (step) {
            case 1:
                return (
                    <>
                    <Form.Group controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={firstName} 
                            onChange={(e) => setFirstName(e.target.value)}
                            placeholder="First name"
                        />
                        { errors && errors.firstName && <Form.Label className='error'>{errors.firstName}</Form.Label> }
                    </Form.Group>
                    <Form.Group controlId="formLasttName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={lastName} 
                            onChange={(e) => setLastName(e.target.value)}
                            placeholder="Last name"
                        />
                        { errors && errors.lastName && <Form.Label className='error'>{errors.lastName}</Form.Label> }
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type="text" 
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                        />
                        { errors && errors.email && <Form.Label className='error'>{errors.email}</Form.Label> }
                    </Form.Group>
                     <Form.Group controlId="formPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="password-input">
                        <Form.Control
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        style={{ borderRadius: "4px 0 0 4px" }}
                        />
                        <InputGroup.Text style={{ backgroundColor: "e8f0fe", height: "36px" , border: "none", cursor: "pointer" , borderRadius: "0 4px 4px 0" }} onClick={togglePasswordVisibility}>
                        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-toggle-icon" style={{ color: "#000", border: "none" }} />
                        </InputGroup.Text>
                    </InputGroup>
                    { errors && errors.password && <Form.Label className='error'>{errors.password}</Form.Label> }
                    </Form.Group>
                    <Form.Group controlId="formConfirmPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup className="password-input">
                        <Form.Control
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Confirm Password"
                        style={{ paddingRight: "40px" }}
                        />
                        <InputGroup.Text style={{ backgroundColor: "e8f0fe", height: "36px" , border: "none", cursor: "pointer",  right: "0", zIndex: "1" }} onClick={toggleConfirmPasswordVisibility}>
                        <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} className="password-toggle-icon" style={{ color: "#000", border: "none" }} />
                        </InputGroup.Text>
                        
                    </InputGroup>
                    { errors && errors.confirmPassword && <Form.Label className='error'>{errors.confirmPassword}</Form.Label> }
                    </Form.Group>
                                    <Form.Group controlId="formPhoneNumber">
                    <Form.Label>Phone number</Form.Label>
                    <Row>
                        <Col xs={3}>
                        <Form.Control 
                            as="select" 
                            value={phoneInternationalCode}
                            onChange={(e) => setPhoneInternationalCode(e.target.value)}
                        >
                            <option value="option">Select</option>
                            <option value="+54">Argentina (+54)</option>
                            <option value="+61">Australia (+61)</option>
                            <option value="+55">Brazil (+55)</option>
                            <option value="+86">China (+86)</option>
                            <option value="+1">Canada (+1)</option>
                            <option value="+45">Denmark (+45)</option>
                            <option value="+358">Finland (+358)</option>
                            <option value="+33">France (+33)</option>
                            <option value="+49">Germany (+49)</option>
                            <option value="+91">India (+91)</option>
                            <option value="+39">Italy (+39)</option>
                            <option value="+81">Japan (+81)</option>
                            <option value="+64">New Zealand (+64)</option>
                            <option value="+31">Netherlands (+31)</option>
                            <option value="+234">Nigeria (+234)</option>
                            <option value="+47">Norway (+47)</option>
                            <option value="+48">Poland (+48)</option>
                            <option value="+351">Portugal (+351)</option>
                            <option value="+7">Russia (+7)</option>
                            <option value="+966">Saudi Arabia (+966)</option>
                            <option value="+27">South Africa (+27)</option>
                            <option value="+82">South Korea (+82)</option>
                            <option value="+34">Spain (+34)</option>
                            <option value="+46">Sweden (+46)</option>
                            <option value="+41">Switzerland (+41)</option>
                            <option value="+90">Turkey (+90)</option>
                            <option value="+44">United Kingdom (+44)</option>
                            <option value="+1">USA (+1)</option>
                        </Form.Control>
                        { errors && errors.phoneInternationalCode && <Form.Label className='error'>{errors.phoneInternationalCode}</Form.Label> }                        
                        </Col>
                        <Col xs={9}>
                        { errors && errors.phoneNumber && <Form.Label className='error'>{errors.phoneNumber}</Form.Label> }
                        </Col>
                    </Row>
                    </Form.Group>
                    
                    </>                    
                );
                case 2:
                    return (
                        <Form.Group controlId="formUserType">
                          <Form.Label>Select user type:</Form.Label>
                          {/* Implement component for user type selection here */}
                          <Form.Control
                            as="select"
                            value={userType}
                            onChange={(e) => setUserType(e.target.value)}
                          >
                            <option value="option">Select an option</option>
                            <option value="athlete">Athlete</option>
                            <option value="coach">Coach</option>
                            {/* <option value="team">Team</option>
                            <option value="agent">Agent</option> */}

                          </Form.Control>
                          { errors && errors.userType && <Form.Label className='error'>{errors.userType}</Form.Label> }
                          {/* Display additional form fields depending on selected user type, defined here */}
                        </Form.Group>
                      );
                case 3:
                    return (
                        <> 
                        </>
                    );
            case 4:
                // Implement your API integration logic here
                return null;
            case 5:
                return null;
            default:
                return null;
        }
    };

    return (
        <Container className="create-account-container">

            <Row style={{ 'height' : '50px', 'marginBottom' : '40px' }}>

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'left', 'lineHeight' : '50px'}}>
                    <div style={{
                        color: "white",
                        display: "inline-block",
                        cursor: "pointer",
                        height : '50px',
                        lineHeight : '50px'
                    }}
                        onClick={handleBack}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                </Col>
                
                <Col xs={6} md={6} lg={6} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>
                    <div style={{ 'color' : '#fff', 'textAlign' : 'center', 'height' : '50px', 'lineHeight' : '50px' }}>Create Account</div>
                </Col>
                

                <Col xs={3} md={3} lg={3} style={{ 'height' : '50px', 'textAlign' : 'center', 'lineHeight' : '50px'}}>                   
                    
                    <div style={{ lineHeight : '50px' }}>

                        <img src={CompanyLogo} style={{ paddingTop : '13px'}} width={30} alt="Logo" />

                    </div>
                                      
                </Col>
                
                <Col xs={12} md={12} lg={12}>
                    <ProgressBar className="mb-4 custom-progress-bar" style={{ height : '3px' }} now={(step / 3) * 100} />
                </Col>
                
            </Row>

            <Row>

            <Col xs={12} md={{ span: 6, offset: 3 }} lg={{ span: 6, offset: 3 }} className="mx-auto">
                    
                    {renderStepContent()}
                    
                  
                    <div className="navigation-buttons mt-4">
                        {step < 3 && 
                            <Button variant="primary" style={{ "border" : "none", "width" : "100%", "background": "linear-gradient(to right, #9F3F86, #1BA9CE)" }} onClick={handleNext}>
                            Continue
                            </Button>
                        }
                    </div>
                
                </Col>

            </Row>

        </Container>
    );

}