import React, { useState, useEffect } from 'react';

// Components

import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

// Packages
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// CSS

import './Languages.css'

function FAQ() {

    const navigate = useNavigate();
    const [menuDisplay, setMenuDisplay] = useState(false);

    const handleOnOpen = () => {
        setMenuDisplay(true);
    }

    const { t, i18n } = useTranslation();

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };

    useEffect(() => {}, []);

    const handleNavigation = (path) =>{
        navigate(path);
    }

    return (
        <>
            <Header />

            <Container className="create-account-container text-white">
                
                <h1 className="text-white mb-4">Select your language</h1>

                <Row className="py-4 mb-2">
                    <Col xs={6}>
                        <h3>Your selected language is:</h3>
                    </Col>
                    <Col className="text-end" xs={6}>
                        <div>{t('language')}</div>
                    </Col>
                </Row>
                

                <div>

                    <Row className="py-4 mb-2" onClick={() => changeLanguage('en')} style={{ borderBottom: '1px solid white', cursor : 'pointer' }}>
                        <Col xs={3}>
                            <span style={{ 'fontSize' : '1.3em' }} className="fi fi-us"></span>
                        </Col>
                        <Col xs={6}>
                            <div style={{ 'fontSize' : '1.3em' }}><b className="ml-4">English</b></div>
                        </Col>
                        <Col xs={3} className="text-end">
                            <FontAwesomeIcon style={{ 'fontSize' : '1.3em' }} icon={faChevronRight} className="arrowIcon" />
                        </Col>
                    </Row>

                    <Row className="py-4 mb-2" onClick={() => changeLanguage('es')} style={{ borderBottom: '1px solid white', cursor : 'pointer' }}>
                        <Col xs={3}>
                            <span style={{ 'fontSize' : '1.3em' }} className="fi fi-es"></span>
                        </Col>
                        <Col xs={6}>
                            <div style={{ 'fontSize' : '1.3em' }}><b className="ml-4">Español</b></div>
                        </Col>
                        <Col xs={3} className="text-end">
                            <FontAwesomeIcon style={{ 'fontSize' : '1.3em' }} icon={faChevronRight} className="arrowIcon" />
                        </Col>
                    </Row>

                    <Row className="py-4 mb-2" onClick={() => changeLanguage('pt')} style={{ borderBottom: '1px solid white', cursor : 'pointer' }}>
                        <Col xs={3}>
                            <span style={{ 'fontSize' : '1.3em' }} className="fi fi-pt"></span>
                        </Col>
                        <Col xs={6}>
                            <div style={{ 'fontSize' : '1.3em' }}><b className="ml-4">Português</b></div>
                        </Col>
                        <Col xs={3} className="text-end">
                            <FontAwesomeIcon style={{ 'fontSize' : '1.3em' }} icon={faChevronRight} className="arrowIcon" />
                        </Col>
                    </Row>

                    <Row className="py-4 mb-2" onClick={() => changeLanguage('fr')} style={{ borderBottom: '1px solid white', cursor : 'pointer' }}>
                        <Col xs={3}>
                            <span style={{ 'fontSize' : '1.3em' }} className="fi fi-fr"></span>
                        </Col>
                        <Col xs={6}>
                            <div style={{ 'fontSize' : '1.3em' }}><b className="ml-4">Français</b></div>
                        </Col>
                        <Col xs={3} className="text-end">
                            <FontAwesomeIcon style={{ 'fontSize' : '1.3em' }} icon={faChevronRight} className="arrowIcon" />
                        </Col>
                    </Row>

                </div>

            </Container>

            <Footer />
        </>
    );
}

export default FAQ;